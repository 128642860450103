/* version: 0.2 */

.terminal {
  background-color: black;
  color: green;
  font-family: monospace;
  padding: 10px;
  height: 400px;
  width: 600px;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.output {
  margin-bottom: 10px;
}

.input-line {
  display: flex;
}

.input-line input {
  background-color: black;
  color: green;
  border: none;
  flex-grow: 1;
  outline: none;
}

